import React, { useEffect, useState } from "react";
import Button, { BUTTON_COLOR, BUTTON_ICONS, BUTTON_POSITION } from "./Button";
import { useRouteMatch } from "react-router-dom";
import IsLoggedIn from "./IsLoggedIn";
import useBasket from "../Context/BasketContext/useBasket";
import useCustomerMemberships from "../Context/CustomerMembershipsContext";
import GenerateBasketFooterLink from "./GenerateCheckoutLink";

const SiteHeader = ({ isAccountPage }: ISiteHeaderProps) => {
    const lesOrmesUrl = "https://www.lesormesjersey.co.uk/";
    const [basketFooterLink, setBasketFooterLink] = useState("");
    const match = useRouteMatch();

    const isLoggedIn = IsLoggedIn();

    const {
        basketData,
        areThereBasketItems,
        areThereBasketActivities,
        areThereBasketMemberships,
        areBookingNamesRequired
    } = useBasket();
    const { hasCustomerMemberships } = useCustomerMemberships();

    useEffect(() => {
        //console.log("we have items");

        const link = isLoggedIn
            ? GenerateBasketFooterLink(
                  areThereBasketActivities,
                  areBookingNamesRequired,
                  hasCustomerMemberships,
                  match.path
              )
            : "/log-in";

        setBasketFooterLink(link);
    }, [areThereBasketItems]);

    return (
        <div className="o-site-header fw">
            <div className="pw">
                <div className="site-header-container">
                    <div className="site-header-links">
                        <a
                            className="logo-link"
                            href={`${lesOrmesUrl}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src="../../../../images/logo.svg"
                                alt="Les Ormes Logo"
                            />
                        </a>
                        <a
                            href={`${lesOrmesUrl}discover-les-ormes/`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Discover Les Ormes
                        </a>
                        <a
                            href={`${lesOrmesUrl}accommodation/`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Accommodation
                        </a>
                        <a
                            href={`${lesOrmesUrl}things-to-do/`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Things to do
                        </a>
                    </div>

                    <div className="site-header-account">
                        {isAccountPage ? (
                            <>
                                <Button
                                    title="Checkout"
                                    icon={BUTTON_ICONS.ACTIVITIES}
                                    color={BUTTON_COLOR.PRIMARY}
                                    position={BUTTON_POSITION.LEFT}
                                    style={{ marginRight: "10px" }}
                                    link={basketFooterLink}
                                />{" "}
                                <Button
                                    title="View all activities"
                                    icon={BUTTON_ICONS.ACTIVITIES}
                                    color={BUTTON_COLOR.SECONDARY}
                                    link="/"
                                />
                            </>
                        ) : (
                            <>
                                <Button
                                    title="Checkout"
                                    icon={BUTTON_ICONS.ACTIVITIES}
                                    color={BUTTON_COLOR.PRIMARY}
                                    position={BUTTON_POSITION.LEFT}
                                    style={{ marginRight: "10px" }}
                                    link={basketFooterLink}
                                />{" "}
                                <Button
                                    title="My Account"
                                    icon={BUTTON_ICONS.ACCOUNT}
                                    color={BUTTON_COLOR.WARNING}
                                    position={BUTTON_POSITION.RIGHT}
                                    link="/account/profile"
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SiteHeader;

export interface ISiteHeaderProps {
    isAccountPage?: boolean;
}
