import React, { useEffect, useRef, useState } from "react";
import useBasket from "../../Context/BasketContext/useBasket";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import PageHeader from "../../Util/PageHeader";
import Basket from "../../Basket/Basket";
import IsLoggedIn from "../../Util/IsLoggedIn";
import useCustomerMemberships from "../../Context/CustomerMembershipsContext";
import GenerateBasketFooterLink from "../../Util/GenerateCheckoutLink";

const CheckoutIntegrate = () => {
    const { setBasketId, renewBasket } = useBasket();
    const { basketId }: { basketId: string | undefined } = useParams();
    const { setRedirectTo } = useAppContext();
    const [basketFooterLink, setBasketFooterLink] = useState("");
    const buttonRef = useRef(null);
    const match = useRouteMatch();

    const isLoggedIn = IsLoggedIn();

    const {
        areThereBasketItems,
        areThereBasketActivities,
        areBookingNamesRequired,
        basketData
    } = useBasket();
    const { hasCustomerMemberships } = useCustomerMemberships();

    let timeout = setTimeout(() => {
        window.location.href = "/check-out";
    }, 3000);

    useEffect(() => {
        if (basketData) {
            console.log("we have items!");
            console.log("areBookingNamesRequired", areBookingNamesRequired);

            // http://localhost:8090/check-out/integrate/66ace9ada7b11b000a88ea31
            console.log("hasCustomerMemberships", hasCustomerMemberships);
            console.log("areThereBasketActivities", areThereBasketActivities);

            const link = isLoggedIn
                ? GenerateBasketFooterLink(
                      areThereBasketActivities,
                      areBookingNamesRequired,
                      hasCustomerMemberships,
                      match.path
                  )
                : "/log-in";

            setBasketFooterLink(link);
            console.log("basketFooterLink", basketFooterLink);

            clearTimeout(timeout);
            window.location.href = link;
        }
    }, [basketData]);

    if (basketId) {
        console.log("found bid", basketId);
        setBasketId(basketId ?? "");

        try {
            renewBasket({ variables: { basketId: basketId ?? "" } });
        } catch (e) {
            console.log("error renewing basket", e);
        }
    }
    //  else {
    //     window.location.href = "/log-in/?nobasket";
    // }

    return (
        <div className="fw">
            <PageHeader title="Activities check-out" />
            <div className="pw">
                <div className="clearfix" />
                <div className="content fw">
                    <div className="two-thirds-container">
                        {/* <p>Link: {basketFooterLink}</p>
                            <Link to={"/check-out"}>
                                <button
                                    ref={buttonRef}
                                    className="m-btn fw"
                                    id="checkout-button"
                                    data-testid="checkout-button"
                                >
                                    Continue to Payment
                                </button>
                            </Link> */}
                        <div className="m-checkin a-box v-box-secondary">
                            <h5>Please wait a second...</h5>
                            <br />
                            <br />
                        </div>
                        <div className="clearfix" />
                    </div>
                    <aside
                        id="basket-scroll"
                        className="sidebar third-container right"
                    >
                        <Basket
                            isCheckout={true}
                            shouldShowMembershipWarning={true}
                        />
                    </aside>
                </div>
            </div>
        </div>
    );
};

export default CheckoutIntegrate;
